import React, { useState, useEffect } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import breakpoints from "../../../styles/breakpoints"
import ContentContainer from "../../Core/Containers/ContentContainer"
import DoubleUnderline from "../../../resources/img/ui/double-underline.svg"
import OnClickButton from "../../Core/Buttons/OnClickButton"
import IngredientPopup from "../IngredientPopup"
import { setBodyOverflow } from "../../../services/core/browser"

const IngredientsContainer = styled.div`
  padding: 270px 0;
  background: ${colors.vanilla};
  @media(max-width: ${breakpoints.md}){
    display: ${(props) => props.desktopVersion && "none"};
  }
  @media(max-width: ${breakpoints.md}) {
    padding: 60px 0;
  }
  @media (min-width: ${breakpoints.md}) {
    background: ${colors.vanilla};
    padding: 120px 30px 180px;
  }
`

const TitleBlock = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  position: relative;
`

const Title = styled.h2`
  ${fonts.garamondRegular};
  font-size: 32px;
  letter-spacing: normal;
  line-height: 1.15;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    font-size: 48px;
    letter-spacing: -0.4px;
  }
`
const Underline = styled.img`
  position: absolute;
  bottom: -8px;
  width: 125px;
  right: 0;
  @media (min-width: ${breakpoints.md}) {
    width: 190px;
  }
`

const IngredientsList = styled.ul`
  margin: 50px auto 30px;

  li {
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 20px 25px;
    text-align: center;
    h3 {
      ${fonts.garamondRegular};
      font-size: 26px;
      line-height: 1.2;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      line-height: 1.2;
    }
  }

  li.active {
    border: 1px solid ${colors.orange};
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    li {
      width: 50%;
    }
  }
`

const ButtonContainer = styled.div`
  margin-top: 30px;
`

const Ingredients = (props) => {

  const { title, popupButtonLabel, ingredients, desktop } = props
  const [popupOpen, setPopupOpen] = useState(false)

  const ingredientsList = []

  ingredients && ingredients.forEach((ingredient) => {
    if (ingredient.activeIngredient === "active") {
      ingredientsList.unshift(ingredient)
    } else {
      ingredientsList.push(ingredient)
    }
  })

  useEffect(() => {
    if (popupOpen) {
      setBodyOverflow(true)
    } else {
      setBodyOverflow(false)
    }
  }, [popupOpen])

  return (
    <React.Fragment>
      <IngredientsContainer desktopVersion={desktop}>
        <ContentContainer>
          <TitleBlock>
            <TitleContainer>
              <Title>{title}</Title>
              <Underline src={DoubleUnderline} alt="Underline" />
            </TitleContainer>
          </TitleBlock>
          <IngredientsList>
            {ingredientsList.map((ingredient, index) => {
              const { name, description, activeIngredient } = ingredient
              return (
                <li key={index} className={ingredient.activeIngredient === "active" ? "active" : null}>
                  <h3>{name}</h3>
                  <p> {description}</p>
                </li>
              )
            })}
          </IngredientsList>
          <ButtonContainer>
            <OnClickButton onClick={() => setPopupOpen(true)}>
              <span>{popupButtonLabel}</span>
            </OnClickButton>
          </ButtonContainer>
        </ContentContainer>
      </IngredientsContainer>
      <IngredientPopup {...props} open={popupOpen} handlePopup={setPopupOpen} />
    </React.Fragment>
  )
}

export default Ingredients
