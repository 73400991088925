import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Containers/ContentContainer"
import QuestionIcon from "../../resources/img/ui/question-mark.svg"
import OnClickButton from "../Core/Buttons/OnClickButton"

const FAQContainer = styled.div`
  padding: 180px 0 20px;
  background: transparent;
  @media (min-width: ${breakpoints.md}) {
    padding: 130px 0 20px;
  }
  @media(max-width: ${breakpoints.md}){
    padding-top: 60px;
    display: ${(props) => props.desktopVersion && "none"};
  }
`

const FaqContents = styled.div`
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 30px;
  }
`

const TitleSection = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  max-width: 1120px;
  margin: auto;
`

const TitleBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.h2`
  ${fonts.garamondRegular};
  font-size: 40px;
  letter-spacing: -0.3px;
  line-height: 1.1;
  @media (min-width: ${breakpoints.md}) {
    font-size: 46px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 52px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 60px;
  }
`

const QuestionMark = styled.img`
  width: 42px;
  position: absolute;
  :first-of-type {
    left: -25px;
    top: -23px;
    transform: translateX(-100%) rotate(-24deg);
  }
  :last-of-type {
    right: -15px;
    transform: translateX(100%) rotate(5deg);
    top: -52px;
  }

  @media (min-width: ${breakpoints.md}) {
    :first-of-type{
      left: -20px;
      top: 0;
      transform: translateX(-100%) rotate(-26deg);
    }
    :last-of-type {
      right: -10px;
      transform: translateX(100%);
      top: -38px;
    }
  }
`

const FaqSection = styled.div`
  margin-top: 40px;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-wrap: wrap;
    max-width: 1120px;
    margin: auto;
    margin-top: 70px;
  }
`

const CardContainer = styled.div`
  margin-bottom: 15px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 15px;
    margin-bottom: 30px;
    flex: 1;
    min-width: 50%;
  }
`

const FaqCard = styled.div`
  border: 1px solid ${colors.charcoal};
  padding: 30px;
  max-width: 440px;
  margin: auto;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    max-width: unset;
  }
`

const Question = styled.h3`
  ${fonts.garamondRegular};
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 8px;
`

const Answer = styled.div`
  font-size: 16px;
  line-height: 1.33;
`

const ButtonContainer = styled.div`
  margin-top: 25px;
`

const FAQs = (props) => {
  

  const [showMore, setShowMore] = useState(4)
  const { buttonText, title, faqs, set, desktop } = props
  return (
    <FAQContainer white={!set? 1 : 0} desktopVersion={desktop}>
      <ContentContainer>
        <FaqContents>
          <TitleBlock>
            <TitleSection>
              <QuestionMark src={QuestionIcon} alt="Question Mark" />
              <Title>{title}</Title>
              <QuestionMark src={QuestionIcon} alt="Question Mark" />
            </TitleSection>
          </TitleBlock>
          <FaqSection open={showMore > 4}>
            {faqs.map((faq, index) => {
              const { question, answer } = faq
              return index + 1 <= showMore ? (
                <CardContainer key={index}>
                  <FaqCard>
                    <div>
                      {question && (
                        <Question
                          dangerouslySetInnerHTML={{
                            __html: question.childMarkdownRemark.html,
                          }}
                        />
                      )}
                      {answer && (
                        <Answer
                          dangerouslySetInnerHTML={{
                            __html: answer.childMarkdownRemark.html,
                          }}
                        />
                      )}
                    </div>
                  </FaqCard>
                </CardContainer>
              ) : null
            })}
          </FaqSection>
          {buttonText && showMore < faqs.length && (
            <ButtonContainer>
              <OnClickButton onClick={() => setShowMore(showMore + 4)}>
                <div>{buttonText}</div>
              </OnClickButton>
            </ButtonContainer>
          )}
        </FaqContents>
      </ContentContainer>
    </FAQContainer>
  )
}

export default FAQs
