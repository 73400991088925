import React, { Fragment, useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Ingredients from "./Product/Ingredients"
import HowToSection from "./Product/HowToSection"
import FAQs from "./FAQs"
import PlusIcon from "../../resources/img/ui/icon-plus-2.svg"
import MinusIcon from "../../resources/img/ui/icon-minus-2.svg"

const MobileContentWrapper = styled.div`
    @media(min-width: ${breakpoints.md}){
        max-width: 70%;
        margin: 40px auto;

    }
`

const AccordionContainer = styled.div`
  margin:20px 0px 0px 0px;
`
const Dropdown = styled.div`
  ${fonts.garamondRegular};
  border-bottom: 1px solid ${colors.charcoal};
  background: transparent;
  transition: all 0.5s ease-in-out;

`
const DropdownTitle = styled.div`
  font-size: 28px;
  line-height: 1.2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 28px 23px 32px;
  min-height: 80px;
  cursor: pointer;
`
const Arrow = styled.img`
  height: 11px;
  transform: ${props => props.displayed ? "rotate(180deg)" : "none"};
  transition: all 0.5s ease-in-out;
`

const Drawer = styled.div`
  max-height: ${(props) => (props.open ? "500vh" : "0")};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  p{
    padding:25px;
  }
`




const PDPMobileContent = (props) => {
  
    const [openIndex, setOpenIndex] = useState(null)
    
    
    const accordionContent = props.accordion;

    const handleDropdown = (index) => {
      if (openIndex === index) {
        setOpenIndex(null)
      } else {
        setOpenIndex(index)
      }
    }   
    return(
        <MobileContentWrapper>

            <AccordionContainer>
              {
                accordionContent.map((section, index) => {
                  const type = section.__typename
                  const isActive = index === openIndex
                    switch(type){
               
                      case 'ContentfulIngredients':
                      return( 
                        <Dropdown key={index} open={isActive}>
                          <DropdownTitle onClick={() => handleDropdown(index)} onKeyDown={() => handleDropdown(index)} role="button" tabIndex="0">
                            <span>Ingredients</span> <Arrow src={`${isActive ? MinusIcon : PlusIcon}`} alt={`${isActive ? "Close" : "Open"}`} displayed={isActive ? 1 : 0}/>
                          </DropdownTitle> 
                          <Drawer open={isActive}>
                            <Ingredients {...section} desktop={0} /> 
                          </Drawer>
                        </Dropdown> 
                      )
                      case 'ContentfulHowToSection':
                      return( 
                        <Dropdown key={index} open={isActive}>
                          <DropdownTitle onClick={() => handleDropdown(index)} onKeyDown={() => handleDropdown(index)} role="button" tabIndex="0">
                            <span>How To Use</span> <Arrow src={`${isActive ? MinusIcon : PlusIcon}`} displayed={isActive ? 1 : 0}/>
                          </DropdownTitle> 
                          <Drawer open={isActive}>
                          <HowToSection {...section} />
                          </Drawer>
                        </Dropdown> 
                      )
                      case 'ContentfulFaQs':
                      return( 
                        <Dropdown key={index} open={isActive}>
                          <DropdownTitle onClick={() => handleDropdown(index)} onKeyDown={() => handleDropdown(index)} role="button" tabIndex="0">
                             <span>FAQs</span> <Arrow src={`${isActive ? MinusIcon : PlusIcon}`} displayed={isActive ? 1 : 0}/>
                          </DropdownTitle>
                          <Drawer open={isActive}>
                            <FAQs desktop={0} {...section} />
                          </Drawer>
                        </Dropdown> 
                      )
                      default:
                        return(
                          <Fragment key={index}></Fragment>
                        )
                    }
                    
                  
                })
              }
            </AccordionContainer>
            
        </MobileContentWrapper>
    )
}

export default PDPMobileContent