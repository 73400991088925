import React, { Suspense, useRef } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Intro from "../components/PDP/Product/Intro"
import { isBrowser } from "../services/core/browser"
import Fallback from "../components/Core/Fallback"
import styled from "styled-components"
import PDPMobileContent from "../components/PDP/PDPMobileContent"
import FeaturesTicker from "../components/PDP/FeaturesTicker"
import { getPdpSchema } from "../services/core/schema"

const QuizCtaBlock = React.lazy(() => import("../components/Core/QuizCtaBlock"))
const Tips = React.lazy(() => import("../components/PDP/Tips"))
const RelatedProducts = React.lazy(() => import("../components/PDP/RelatedProducts"))
const ReviewsBlock = React.lazy(() => import("../components/PDP/ReviewsBlock"))


const ProductContainer = styled.div`
  overflow: hidden;
`

const ProductTemplate = (props) => {

  const getProduct = () => {
    let product = props.data.product
    return product
  }
  const isSSR = typeof window === "undefined"

  let product = getProduct()
  const allReviews = props.pageContext.reviews

  let ratingSum = 0;
  allReviews.forEach(reviewItem => {
    ratingSum += reviewItem.review.rating
  })
  const totalReviews = allReviews.length

  let averageRating = 0;

  if (totalReviews > 0) {
    averageRating = (ratingSum / totalReviews).toFixed(1)
  }

  const ingredients = props.pageContext.ingredients

  const path = props.location.pathname
  const { features, productContent: content } = props.data

  const { descriptionHtml, shopifyId, handle, tags, variants } = product

  const {
    title,
    meta_title,
    meta_description: { meta_description },
    price,
    subscriptionDiscountText,
    recommendedSubscription,
    blocks,
    showBadge,
    badgeLabel,
    shortDescription: { shortDescription },
    description,
    featureList,
    infoList,
    disclaimerList,
    pdpPriceField,
    subscriptionOnlyProduct,
    atcAutoRenewSubcopy,
    atcOneTimePurchaseSubcopy,
    atcPrepaidCopy,
    atcPrepaidSubcopy,
    showBadgesImage,
    badgesImage
  } = content

  const media = product.media.map(mediaItem => {
    const { mediaContentType } = mediaItem
    return {
      contentType: mediaContentType,
      image: mediaContentType === "IMAGE" ? mediaItem.image : mediaContentType === "VIDEO" ? mediaItem.preview.image : null,
      video: mediaContentType === "VIDEO" ? {src: mediaItem.sources[0].url} : null
    }
  })

  product.images = media

  if (product) {
    product.subscriptionDiscountText = subscriptionDiscountText
    product.recommendedSubscription = recommendedSubscription
  }

  const reviewsRef = useRef(null)

  const handleRatingsClick = () => {
    if (isBrowser()) {
      const globalContainer = document.getElementById("global")
      if(reviewsRef.current && globalContainer) {
        const yOffset = -250;
        const y = reviewsRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        globalContainer.scrollTo({top: y, behavior: 'smooth'});
      }
    }
  }

  // const selectOption = (optionName, e) => {
  //   const product = getProduct()

  //   let selectedOptions = options

  //   selectedOptions[optionName] = e.value

  //   setOptions(selectedOptions)

  //   let selectedVariant = variant

  //   selectedVariant = getSelectedVariant(product, options)
  //   setVariant(selectedVariant)
  // }

  let gallery = []

  if (media && media.length > 0) {
    if (media.length === 1) {
      gallery = [media[0]]
    } else {
      gallery = media.slice(1, media.length)
    }
  }

  let outOfStock = false
  if(tags && tags.length > 0) {
    tags.forEach(tag => {
      const sluggedTag = tag.toString().toLowerCase().replace(/ /g, "-");

      if(sluggedTag === "out-of-stock") {
        outOfStock = true
      }
    })
  }
  if(variants && variants.length > 0) {
    variants.forEach(variant => {
      if(variant.availableForSale === false) {
        outOfStock = true
      }
    })
  }

  const productDetail = {
    title: title,
    description: description?.childMarkdownRemark?.html,
    price: price,
    images: gallery,
    products: [product],
    handle: handle,
    currency: "USD",
    set: false,
    outOfStock: outOfStock,
    showBadge: showBadge,
    badgeLabel: badgeLabel,
    shortDescription: shortDescription,
    featureList: featureList,
    infoList: infoList,
    disclaimerList: disclaimerList,
    pdpPriceField: pdpPriceField,
    subscriptionOnlyProduct: subscriptionOnlyProduct,
    oneTimeOptionSubcopy: atcOneTimePurchaseSubcopy,
    subscriptionOptionSubcopy: atcAutoRenewSubcopy,
    prepaidCopy: atcPrepaidCopy,
    prepaidOptionSubcopy: atcPrepaidSubcopy,
    ampedUpsellProducts: props.pageContext.ampedUpsellProducts,
    badgesImage: badgesImage,
    showBadgesImage: showBadgesImage
  }

  const shopifyIdNumber = parseInt(shopifyId.split("/").pop());

  let productImage = null

  if(product.images && product.images.length > 0 && product.images[0].image) {
    productImage = product.images[0].image.src
  }

  const reviewedProduct = {
    productId: shopifyIdNumber,
    title: title,
    handle: handle,
    productImage: productImage
  }

  let schemaInput = {
    title: title,
    imageSrcs: [],
    description: shortDescription,
    price: product?.priceRangeV2?.minVariantPrice?.amount,
    inStock: !outOfStock,
    slug: handle,
    rating: averageRating,
    reviewCount: totalReviews
  }

  product.images.forEach((image, index) => {
    if (typeof image !== "undefined" && index !== 1) {
      schemaInput.imageSrcs.push(image.image.src)
    }
  })

  const schema = getPdpSchema(schemaInput)

  const pageContents = []
  const mobileContent = []
  let benefits = null

  blocks && blocks.forEach((block, index) => {
    const {__typename: type} = block;

    if (type === "ContentfulBenefits") {

      benefits = block
      mobileContent.push({...block})
    }
    if (type === "ContentfulIngredients") {
      mobileContent.push({...block, clearformeIngredients: ingredients})
    }
    if (type === "ContentfulHowToSection") {
      mobileContent.push({...block})
    }
    if (type === "ContentfulReviews") {
      pageContents.push(<ReviewsBlock {...block} key={index} reviewList={allReviews} totalReviews={totalReviews} averageRating={averageRating} ref={reviewsRef} isSet={false} product={reviewedProduct}/>)
    }
    if (type === "ContentfulTitleTextBlock") {
      pageContents.push(<Tips {...block} key={index} />)
    }
    if (type === "ContentfulFaQs") {
      mobileContent.push({...block})
    }
    if (type === "ContentfulProductsCallout") {
      pageContents.push(<RelatedProducts {...block} key={index} />)
    }
    if (type === "ContentfulQuizCallout") {
      pageContents.push(<QuizCtaBlock {...block} key={index} pdp/>)
    }
  })

  let sharingImage = false

  if (gallery.length > 0) {
    sharingImage = gallery[0].image.src.replace("https:", "")
  }

  return (
    <Layout transparentHeader={true} schema={schema}>
      <Seo title={meta_title} description={meta_description} path={path} image={sharingImage}/>
      <ProductContainer>
        <Intro {...productDetail} totalReviews={totalReviews} averageRating={averageRating} handleRatingsClick={handleRatingsClick} benefits={benefits}/>
        <FeaturesTicker features={features.nodes} />
        <PDPMobileContent accordion={mobileContent} features={benefits} />
        {!isSSR && <Suspense fallback={<Fallback/>}>
          {pageContents}
        </Suspense>}
      </ProductContainer>
    </Layout>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  query ProductTemplateQuery($slug: String!, $contentBlockId: String!) {
    product: shopifyProduct(handle: { eq: $slug }) {
      ... shopifyProductFragment
    }
    productContent: contentfulProduct(contentful_id: { eq: $contentBlockId }) {
      id
      title
      meta_title
      meta_description {
        meta_description
      }
      shortDescription {
        shortDescription
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      featureList
      infoList
      price: startingPrice
      pdpPriceField
      subscriptionDiscountText
      recommendedSubscription
      showBadge
      badgeLabel
      subscriptionOnlyProduct
      blocks {
        ...benefitsFragment
        ...ingredientsFragment
        ...howToFragment
        ...faqFragment
        ...reviewsFragment
        ...quizCalloutFragment
        ...productsCalloutFragment
        ...titleTextFragment
      }
      atcAutoRenewSubcopy
      atcOneTimePurchaseSubcopy
      atcPrepaidCopy
      atcPrepaidSubcopy
      showBadgesImage
      badgesImage {
        file {
          url
        }
      }
    }
    setItems: allContentfulSet(
      filter: { bundleProducts: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        ... contentfulSetFragment
      }
    }
    features: allContentfulFeature(sort: { fields: order }) {
      nodes {
        ...featureFragment
      }
    }
  }
`
