import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Close from "../../resources/img/ui/icon-close.svg"
import FocusTrap from "focus-trap-react"
import { lambdaCall } from "../../services/core/lambdaCall"

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(186, 180, 217, 0.6);
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? "visible" : "none")};
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 11;

  @media (min-width: 500px) {
    padding: 20px 60px;
  }
`

const PopupContentContainer = styled.div`
  background: ${colors.white};
  position: relative;
  width: 100%;
  max-width: 840px;
  margin: auto;
  height: auto;
  overflow: auto;
  position: relative;
  max-height: 550px;

  ::-webkit-scrollbar {
    width: 5px;
    background: #f7f5f0;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.charcoal};
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: ${colors.charcoal};
  }
`

const PopupContent = styled.div`
  padding: 30px 20px;
  position: relative;

  @media (min-width: 500px) {
    padding: 30px;
  }
`

const TitleBlock = styled.div`
  text-align: center;
  padding-right: 20px;
`

const Title = styled.h2`
  ${fonts.garamondRegular};
  font-size: 26px;
  line-height: 1.2;
  margin-left: 20px;
`

const IngredientContainer = styled.div`
  margin-top: 30px;

  span {
    font-size: 16px;
    line-height: 20px;
    ${fonts.sofiaProSemiBold};
  }
`

const SubTitle = styled.div`
  ${fonts.sofiaProSemiBold};
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 10px;
`

const Text = styled.div`
  line-height: 1.7;
  font-size: 16px;
  padding: 25px;

  span {
    ${fonts.garamondRegular};
    line-height: 1.7;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }

    @media (min-width: ${breakpoints.md}) {
      line-height: 20px;
      font-size: 17px;
    }
  }
`

const IngredientButton = styled.button`
  all: unset;
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 20px;
  padding: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  img {
    width: 20px;
    height: 20px;
  }

  @media (min-width: ${breakpoints.md}) {
    top: 40px;
    right: 40px;
  }
`

const ImageContainer = styled.div`
  text-align: center;
`

const StyledImage = styled(GatsbyImage)`
  max-width: 500px;
  margin: 20px auto 0;
`

const LightboxOverlay = styled.div`
  display: ${props => props.open ? "block" : "none"};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  position: fixed;
`

const Lightbox = styled.div`
  position: fixed;
  z-index: 13;
  background-color: ${colors.bone};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: fit-content;
  width: calc(100vw - 40px);
  padding: 40px 20px 20px;
  border: 1px solid ${colors.black};
  max-width:  320px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 500px;
    padding: 60px 28px 28px;
  }
 
`

const LightboxCloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  img {
    width: 16px;
    height: 16px;
  }
`

const LightboxHeading = styled.h2`
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.charcoalLight};

  @media (min-width: ${breakpoints.md}) {
    font-size: 24px;
    line-height: 28px;

  }
`

const LightboxDefinition = styled.p`
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 50px !important;
  padding-top: 16px !important;
  max-width: 50ch;
  ${fonts.sofiaPro};
  font-size: 14px;
  line-height: 18px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 20px;
  }
`

const LightboxNote = styled.p`
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 1em !important;
  padding-top: 0 !important;
  max-width: 50ch;
  line-height: 20px;
  font-style: italic;
  ${fonts.sofiaPro};
`

const LightboxBenefits = styled.p`
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-bottom: 1em !important;
  padding-top: 0 !important;
  line-height: 20px;
  max-width: 50ch;
  ${fonts.sofiaPro};

  b {
    font-weight: 700;
  }
`

const LightBoxLogo = styled.p`
  padding-right: 4px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: right;
  text-transform: uppercase;
  ${fonts.sofiaPro};
  font-size: 11px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 12px;
  }
`

const IngredientPopup = (props) => {
  const {
    open,
    handlePopup,
    popupTitle,
    ingredientListTitle,
    ingredientsOverlayText: text,
    ingredient_overlay_image: image,
    clearformeIngredients
  } = props

  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxData, setLightboxData] = useState({})

  const ingredients = clearformeIngredients?.productIngredients[0]?.ingredientGroup[0]?.ingredients

  const handleClick = async (cfmId, productId, ingredient) => {
    const data = {
      productIngredientId: productId,
      cfmIngredientId: cfmId
    }

    const response = await lambdaCall({
      url: "ingredientDefinition",
      method: "POST",
      body: JSON.stringify(data),
    })

    const [statusCode, responseBody] = await Promise.all([response.status, response.text()])

    if (statusCode === 200) {
      setLightboxData(JSON.parse(responseBody))
      setLightboxOpen(true)
    } else {
      setLightboxData({
        ingredientName: ingredient,
        definition: "Ingredient definition currently not available :("
      })
      setLightboxOpen(true)
    }
  }

  return (
    <Overlay open={open}>
      <FocusTrap active={open}>
        <PopupContentContainer>
          <CloseButton onClick={() => handlePopup(false)} aria-label="Close">
            <img src={Close} alt="Close" />
          </CloseButton>
          <PopupContent>
            {popupTitle && (
              <TitleBlock>
                <Title>Ingredients</Title>
              </TitleBlock>
            )}
            <IngredientContainer>
              {ingredients && (
                <>
                  <span>Click an ingredient to learn more:</span>
                  <Text> 
                    {ingredients.map((ingredient, index) => {
                      const { ingredientName, cfmIngredientId, productIngredientId } = ingredient
                      return (
                        <span 
                          key={index}
                          onClick={() => handleClick(cfmIngredientId, productIngredientId, ingredientName)}
                          onKeyUp={() => handleClick(cfmIngredientId, productIngredientId, ingredientName)}
                          tabIndex="0"
                        >{ingredientName}, </span>
                      )
                    })}
                  </Text>
                </> 
              )}
              {image && (
                <ImageContainer>
                  <StyledImage image={getImage(image)} alt={image.title || ""} />
                </ImageContainer>
              )}
            </IngredientContainer>
            <LightboxOverlay open={lightboxOpen} onClick={() => setLightboxOpen(false)}>
              <Lightbox onClick={e => e.stopPropagation()}>
                <LightboxCloseButton onClick={() => setLightboxOpen(false)} aria-label="Close">
                  <img src={Close} alt="Close" />
                </LightboxCloseButton>
                <LightboxHeading>{lightboxData.ingredientName}</LightboxHeading>
                <LightboxDefinition>{lightboxData.definition}</LightboxDefinition>
                {lightboxData.customNote && <LightboxNote>{lightboxData.customNote}</LightboxNote>}
                {lightboxData.qualities && lightboxData.qualities.length > 0 &&
                  <LightboxBenefits><b>Benefits: </b>
                    {lightboxData.qualities?.map((quality, index) => {
                      return <span key={index}>{quality}</span>
                    })}
                  </LightboxBenefits>
                }
                <LightBoxLogo>powered by clearforme</LightBoxLogo>
              </Lightbox>
            </LightboxOverlay>
          </PopupContent>
        </PopupContentContainer>
      </FocusTrap>
    </Overlay>
  )
}

export default IngredientPopup
